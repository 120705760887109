<template>
  <div class="wrap">
    <van-nav-bar
      title="Hồ sơ rút tiền"
      class="record_nav"
      :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'"
    >
      <i slot="left" class="iconfont icon-jiantou1" @click="$router.back()"></i>
      <template #title>
        <span :class="!$isLinkGame ? 'yellow-color' : 'white-color'"
          >kỷ lục chiến thắng</span
        >
      </template>
    </van-nav-bar>
    <van-list
      class="records"
      v-model="getRecordsLoading"
      :finished="!hasMore"
      finished-text="không còn nữa"
      loading-text="Đang tải..."
      @load="getRecords"
    >
      <ListItem
        v-for="(item, index) in records"
        :key="item.id || new Date().getTime() + index"
        :data="{...item.item, indate: item.indate}"
        type="RECORD"
        :padding="16"
      />
      <!-- <div
        class="record_item"
        v-for="(item, index) in records"
        :key="item.id || new Date().getTime() + index"
      >
        <div class="title">{{ item.title }}</div>
        <div class="time">{{ $format(item.indate, "MM/dd/yyyy") }}</div>
      </div> -->
      <!-- <div class="null" v-if="!records.length">ไม่มีบันทึก</div> -->
    </van-list>
  </div>
</template>

<script>
import { Toast } from "vant";
import { isLinkGame, isGameGoLink, $get, $post } from "@/utils/request.js";
import ListItem from "./components/list_item.vue";
export default {
  components: { ListItem },
  data() {
    return {
      recordPage: 0,
      getRecordsLoading: false,
      hasMore: true,
      records: [],
    };
  },
  methods: {
    verifyRes({ ret, msg, data }) {
      return new Promise((resolve, reject) => {
        if (ret == -1) {
          return this.$router.replace("/login");
        }
        if (ret == 1) {
          resolve(data);
          return;
        }
        Toast(msg);
      });
    },
    async getRecords() {
      try {
        this.recordPage++;
        const { data } = await $get("/marketing/gift", {
          act: "list",
          type: "user_gift",
          page: this.recordPage,
        });
        this.verifyRes(data).then((res) => {
          this.records = res.lists || [];
          console.log(this.records, res);
          if (res.last_page == res.current_page) {
            this.hasMore = false;
          }
        });
      } catch (error) {
        console.error("获取中奖列表错误:", error);
      }
    },
  },
};
</script>

<style>
</style>